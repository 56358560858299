import { Dispatch, useCallback } from 'react';
import { useCookies } from 'react-cookie';
import { QueryClient, useQueryClient } from 'react-query';

import { BASKET_DELIVERIES_QUERY_KEY, BASKET_QUERY_KEY, useBasketAddProduct, useDeleteBasket } from '@api/basket';
import { BasketProduct } from '@api/basket/types';
import { ProductDetail, QUERY_KEY_AUTH_OFFERS, QUERY_KEY_OFFERS } from '@api/catalog';
import { getPagination } from '@api/common/helpers';
import { CUSTOMER_PROFILE_KEY, CompareData, FAVORITE_KEY } from '@api/customers';
import { COMPARE_KEY } from '@api/customers/compare';

import { TokenDataProps, useAuth } from '@context/auth';
import { useCartContext } from '@context/cart';

import { EMPTY_USER, EXPIRATION_TIME } from '@scripts/constants';

import { apiFront } from '..';
import { CURRENT_USER_QUERY_KEY } from '.';

export interface LoginData {
    data: {
        access_token: string;
        refresh_token: string;
        expires_in: string;
    };
}

export async function logoutHelper(
    push: (val: string) => void,
    setUser: Dispatch<TokenDataProps>,
    setBasketProducts: (val: []) => void,
    setComparedProducts: (val: []) => void,
    setBasketProductsServices: (val: []) => void,
    queryClient: QueryClient
) {
    await apiFront.logOut();
    queryClient.setQueryData(CURRENT_USER_QUERY_KEY, null);

    queryClient.setQueryData(
        [
            COMPARE_KEY,
            {
                sort: 'id',
                pagination: getPagination(-1),
            },
        ],
        []
    );
    queryClient.setQueryData(
        [
            FAVORITE_KEY,
            {
                sort: 'id',
                pagination: getPagination(-1),
            },
        ],
        []
    );

    setUser(EMPTY_USER);
    setBasketProducts([]);
    setBasketProductsServices([]);
    setComparedProducts([]);
    queryClient.setQueryData(BASKET_DELIVERIES_QUERY_KEY, null);
    queryClient.invalidateQueries(BASKET_DELIVERIES_QUERY_KEY);
    queryClient.setQueryData(BASKET_QUERY_KEY, null);
    push('/');
}

export const useLoginHelper = () => {
    const queryClient = useQueryClient();

    const { setTokenData } = useAuth();
    const { setBasketProducts } = useCartContext();

    const [_, setCookies] = useCookies(['access_token']);

    const { mutateAsync: clearBasket } = useDeleteBasket();
    const { mutateAsync: setProducts } = useBasketAddProduct();

    const invalidateQueries = () => {
        const keys = [
            CUSTOMER_PROFILE_KEY,
            CURRENT_USER_QUERY_KEY,
            QUERY_KEY_OFFERS,
            QUERY_KEY_AUTH_OFFERS,
            FAVORITE_KEY,
            COMPARE_KEY,
            BASKET_QUERY_KEY,
        ];
        keys.forEach(key => queryClient.invalidateQueries(key));
    };

    const loginHelper = useCallback(
        async (
            logInData: {
                login: string;
                password: string;
            },
            setIsLoading: (val: boolean) => void
        ) => {
            setIsLoading(true);

            try {
                const data = await apiFront.post('login', { data: logInData });

                setTokenData({
                    accessToken: data.data.accessToken,
                    hasRefreshToken: Boolean(data.data.refreshToken),
                    expiresAt: data.data.expiresAt,
                });

                invalidateQueries();
            } catch (error: any) {
                return new Error(error.message);
            }
        },
        []
    );

    const loginCodeByPhoneHelper = useCallback(
        async (
            logInData: {
                phone: string;
                code: string;
            },
            setIsLoading: (val: boolean) => void
        ) => {
            setIsLoading(true);

            try {
                const data = await apiFront.post('login', {
                    data: { loginType: 'login:by-phone', ...logInData },
                });

                setTokenData({
                    accessToken: data.data.accessToken,
                    hasRefreshToken: Boolean(data.data.refreshToken),
                    expiresAt: data.data.expiresAt,
                });
                invalidateQueries();
            } catch (error: any) {
                return new Error(error.message);
            }
        },
        []
    );

    const loginCodeByEmailHelper = useCallback(
        async (
            logInData: {
                email: string;
                code: string;
            },
            setIsLoading: (val: boolean) => void
        ) => {
            setIsLoading(true);

            try {
                const data = await apiFront.post('login', {
                    data: { loginType: 'login:by-email', ...logInData },
                });

                setTokenData({
                    accessToken: data.data.accessToken,
                    hasRefreshToken: Boolean(data.data.refreshToken),
                    expiresAt: data.data.expiresAt,
                });

                invalidateQueries();
            } catch (error: any) {
                return new Error(error.message);
            }
        },
        []
    );

    const loginByToken = useCallback(async (logInData: { token: string }) => {
        try {
            await apiFront.post('clear').then(() => setTokenData(EMPTY_USER));
            setTokenData({
                accessToken: logInData.token,
                hasRefreshToken: false,
                expiresAt: EXPIRATION_TIME,
            });

            setCookies('access_token', logInData.token, {
                maxAge: Number(EXPIRATION_TIME),
                secure: true,
                httpOnly: false,
                path: '/',
                sameSite: 'strict',
            });

            invalidateQueries();
        } catch (error: any) {
            return new Error(error.message);
        }
    }, []);

    const registerByCodeHelper = useCallback(
        async (
            logInData: {
                email: string;
                code: string;
                phone: string;
                timezone: string;
                type: number;
                inn?: string;
                ogrn?: string;
                okato?: string;
                okfs?: string;
                okogu?: string;
                okpo?: string;
                oktmo?: string;
                okved?: string;
                org_name?: string;
                org_name_full?: string;
                org_info?: string;
            },
            setIsLoading: (val: boolean) => void
        ) => {
            setIsLoading(true);

            try {
                const data = await apiFront.post('register', { data: logInData });

                setTokenData({
                    accessToken: data.data.accessToken,
                    hasRefreshToken: Boolean(data.data.refreshToken),
                    expiresAt: data.data.expiresAt,
                });

                invalidateQueries();
            } catch (error: any) {
                return new Error(error.message);
            }
        },
        []
    );

    const handleBasketOperations = useCallback(
        async (basketProducts: ProductDetail[], apiBasketData?: BasketProduct[]) => {
            try {
                if (!basketProducts.length) return;

                if (apiBasketData) await clearBasket();
                await setProducts(
                    basketProducts.map(item => ({
                        offer_id: item.offer_id,
                        qty: item.qty,
                    }))
                );
                setBasketProducts([]);

                queryClient.setQueryData(BASKET_DELIVERIES_QUERY_KEY, null);
                queryClient.invalidateQueries(BASKET_DELIVERIES_QUERY_KEY);
            } catch (error: any) {
                setBasketProducts([]);

                return new Error(error.message);
            }
        },
        []
    );

    return {
        loginHelper,
        loginCodeByPhoneHelper,
        loginCodeByEmailHelper,
        registerByCodeHelper,
        loginByToken,
        handleBasketOperations,
    };
};

export const changeComparedOffers = async (
    comparedProducts: ProductDetail[],
    isUser: boolean,
    deleteCompare: (val: CompareData) => void,
    createCompare: (val: CompareData) => void,
    setComparedProducts: (val: ProductDetail[]) => void,
    id: number,
    comparedAuthProductsIdsData: CompareData[],
    productInfo: ProductDetail
) => {
    const newProducts = [...comparedProducts];

    const index = newProducts.findIndex(item => item.id === id);

    if (index >= 0) {
        newProducts.splice(index, 1);
        if (isUser) {
            setComparedProducts(newProducts);
            await deleteCompare({
                id: comparedAuthProductsIdsData.find(item => item.offer_id === id)?.id,
            });
        }
    } else {
        newProducts.push(productInfo);
        if (isUser) {
            await createCompare({
                offer_id: id,
            });
        }
    }

    if (!isUser) {
        setComparedProducts(newProducts);
    }
};
